(function (angular, _) {
    const MyHippoCommonDirectives = angular.module('MyHippoCommons.Directives');

    MyHippoCommonDirectives.directive('hpNewFileUpload', hpFileUploadDirective);
    hpFileUploadDirective.$inject = ['$log', '$timeout', 'toaster', 'UploadService'];
    function hpFileUploadDirective ($log, $timeout, toaster, UploadService) {
        return {
            restrict: 'E',
            template: `
                <div class="hp-field-newuploadfile" ng-class="{'isUploaded': model.upload_id}">
                    <button
                        ng-if="!disabled"
                        type="button"
                        class="btnUpload ion-paperclip"
                        ngf-select="upload($file)"
                        accept="*/*"
                        ngf-multiple="false"
                        ngf-max-size="80MB">
                    </button>
                    <a ng-if="model.upload_id"
                        ng-href="/v1/agent/uploaded/{{model.upload_id}}/url"
                        class="btnView"
                        target="_blank"><!--
                     --><span class="upload-description"><!--
                         --><span class="ion-ios-eye-outline"></span> <!--
                         --><span class="upload-id">({{model.upload_id}})</span> <!--
                         --><span class="filename">{{displayName()}}</span><!--
                     --></span><!--
                 --></a>
                    <button
                        ng-if="model.upload_id && !disabled"
                        class="btnRemove ion-ios-trash-outline"
                        ng-click="remove()"
                        target="_blank">
                    </button>
                </div>
            `,
            scope: {
                model: '=',
                onRemove: '&',
                onUpload: '&',
                maxFilenameDisplay: '<'
            },
            replace: true,
            link: function ($scope, $element, $attrs) {
                if (!$scope.maxFilenameDisplay) {
                    $scope.maxFilenameDisplay = 24;
                } else {
                    $scope.maxFilenameDisplay = parseInt($scope.maxFilenameDisplay, 10);
                }

                $attrs.$observe('disabled', function (val) { $scope.disabled = val; });

                $scope.upload = function (file) {
                    $log.info('uploaded file', file);
                    if (!file) return;
                    toaster.pop('success', 'Image Upload', 'Upload started. Please wait ...');

                    UploadService.uploadFile(file).then(function (upload) {
                        toaster.pop('success', 'Image Upload', 'Successful upload!');

                        $scope.model.original_filename = file.name;
                        $scope.model.upload_id = upload.id;

                        $scope.onUpload();
                    }, function (err) {
                        $log.info('upload error', err);
                        toaster.pop('error', 'Image Upload', 'Failed to upload file. Try again later.');
                    });
                };

                $scope.displayName = function () {
                    const model = $scope.model;

                    if (model.original_filename.length < $scope.maxFilenameDisplay) {
                        return model.original_filename;
                    } else {
                        const filenameSliceSize = Math.floor(($scope.maxFilenameDisplay - 3) / 2);
                        return model.original_filename.slice(0, filenameSliceSize) + '...' +
                            model.original_filename.slice(
                                model.original_filename.length - filenameSliceSize
                            );
                    }
                };

                $scope.remove = function () {
                    $scope.onRemove(this);
                };
            }
        };
    }
})(window.angular, window._);
